import React from "react";

import FooterLogo from "../assets/images/logo-1-1.png";
import qrCodeImage from '../assets/images/qr1.jpg';
import footerBg from '../assets/images/footer-bg.png';
import footerLogo from '../assets/images/IUNEW3-duoi.png';
import footerShape from '../assets/images/footer-top-shape.png';
import { Facebook, Twitter, Instagram, Pinterest, Telephone, GeoAlt, Globe,  Building,FileText } from 'react-bootstrap-icons';

const Footer = () => {
  return (

<div>

<div className="shape-img subscribe-wrap">
  <img src={footerShape} alt="footer shape" className="img-fluid" />
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-6">
        <div className="w-100 position-relative">
          
        </div>
      </div>
    </div>
  </div>
</div>



<footer className="footer-section">
      {/* Phần shape image */}
      <div className="shape-img subscribe-wrap">
        <img src={footerShape} alt="footer shape" className="img-fluid" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="w-100 position-relative">
               
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Phần footer */}
<div
  className="footer-top pt-150 background-img-2"
  style={{ background: `url(${footerBg}) no-repeat center top / cover`, paddingTop: '100px' }}
>
  <div className="container">
    <div className="row">
      {/* Logo and Social Icons Section */}
      <div className="col-md-6 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
        <div className="footer-logo-wrap text-white d-flex flex-column align-items-center align-items-md-start"> {/* Flexbox for better alignment */}
          {/* <img src={footerLogo} alt="footer logo" width="220px" className="img-fluid mb-3" /> */}
          <div
  style={{
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '250px', // Adjust the size of the circular area
    height: '250px', // Adjust the size of the circular area
    background: '#fff', // White background for the concave effect
    borderRadius: '50%', // Make it circular
    margin: '0 auto', // Center the element horizontally
    boxShadow: '0 0 30px rgba(0, 0, 0, 0.2)', // Optional: Add shadow for depth
  }}
>
  <img
    src={footerLogo}
    alt="footer logo"
    style={{
      width: '220px', // Adjust the size of the logo as needed
      zIndex: 1, // Ensure the logo is on top of the white background
    }}
  />
</div>

         
        </div>
      </div>

      {/* About Us Section */}
      <div className="col-md-6">
        <div className="footer-about-wrap text-white" style={{ paddingLeft: '50px' }}> {/* Adjust padding as needed */}
        <h5 className="mb-3 text-white" style={{ fontWeight: 'bold' }}>VỀ CHÚNG TÔI</h5>
          <ul className="list-unstyled support-list">
  <li className="mb-2 d-flex align-items-start"> {/* Giảm khoảng cách từ mb-3 xuống mb-2 */}
    <Building className="me-2" size={22} style={{ flexShrink: 0 }} /> {/* Giảm "me-3" xuống "me-2" để khoảng cách giữa icon và chữ nhỏ hơn */}
    <span style={{ fontSize: '16px', paddingLeft: '8px' }}>Công Ty TNHH Nối Group</span> {/* Giảm padding-left */}
  </li>
  <li className="mb-2 d-flex align-items-start"> {/* Giảm khoảng cách từ mb-3 xuống mb-2 */}
    <FileText className="me-2" size={22} style={{ flexShrink: 0 }} />
    <span style={{ fontSize: '16px', paddingLeft: '8px' }}>MST: 0318379051</span>
  </li>
  <li className="mb-2 d-flex align-items-start"> {/* Giảm khoảng cách từ mb-3 xuống mb-2 */}
    <GeoAlt className="me-2" size={22} style={{ flexShrink: 0 }} />
    <span style={{ fontSize: '16px', paddingLeft: '8px' }}>Tầng 08, Toà nhà Pearl Plaza, Số 561A Điện Biên Phủ, P.25, Q.Bình Thạnh, TP.HCM</span>
  </li>
  <li className="mb-2 d-flex align-items-start"> {/* Giảm khoảng cách từ mb-3 xuống mb-2 */}
    <Telephone className="me-2" size={26} style={{ flexShrink: 0 }} />
    <span style={{ fontSize: '16px', paddingLeft: '8px' }}>0866428869</span>
  </li>
  <li className="mb-2 d-flex align-items-start"> {/* Giảm khoảng cách từ mb-3 xuống mb-2 */}
    <Globe className="me-2" size={22} style={{ flexShrink: 0 }} />
    <span style={{ fontSize: '16px', paddingLeft: '8px' }}>
      <a href="https://noi.dating/" style={{ color: '#fff' }}>www.noi.dating</a>
    </span>
  </li>
</ul>



        </div>
      </div>
    </div>
  </div>

  {/* Footer Bottom Section */}
  <div className="footer-bottom border-gray-light mt-5 py-3">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="copyright-wrap small-text text-center">
            <p className="mb-0 text-white">© 2024 - Nối Group</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</footer>




</div>



  );
};

export default Footer;


